//// ================================ Imports ======================================
//scss
import 'bootstrap/dist/css/bootstrap-grid.css';
import 'nouislider/dist/nouislider.css';
import '../scss/index.scss';

//js
import $ from 'jquery';
import Swiper from 'swiper';
import {
    Fancybox,
    Carousel,
    Panzoom
} from '@fancyapps/ui';
import Choices from 'choices.js';
import autoComplete from '@tarekraafat/autocomplete.js';
import validate from 'validate.js';
import ApexCharts from 'apexcharts';
import {
    rippleEffect,
    Ripple
} from 'data-ripple';
import noUiSlider from 'nouislider';
import Scrollbar from 'smooth-scrollbar';
import UIkit from 'uikit';
import JustValidate from 'just-validate';

//// ================================ Code ======================================

const headerTabs = document.querySelectorAll('.header__list-item button');
let lastClicked = headerTabs[0];

for (let i = 0; i < headerTabs.length; i++) {
    headerTabs[i].addEventListener('click', function () {
        lastClicked.classList.remove('active');
        this.classList.add('active');

        lastClicked = this;
    })
}

const section = document.querySelectorAll('.double__section');

let tab = () => {
    section.forEach(item => {

        let tabNav1 = item.querySelector('.first__tab');
        let tabNav2 = item.querySelector('.last__tab');
        let contents = item.querySelectorAll('.scroll__block-border');

        tabNav1?.addEventListener('click', () => {
            tabNav1.classList.add('is-active');
            tabNav2.classList.remove('is-active');
            contents.forEach(item => {
                if (item.dataset.contentName == tabNav1.dataset.tabName) {
                    item.classList.add('is-active');
                } else {
                    item.classList.remove('is-active')
                }
            })
        })
        tabNav2?.addEventListener('click', () => {
            tabNav2.classList.add('is-active');
            tabNav1.classList.remove('is-active');
            contents.forEach(item => {
                if (item.dataset.contentName == tabNav2.dataset.tabName) {
                    item.classList.add('is-active');
                } else {
                    item.classList.remove('is-active')
                }
            })
        })
    })
}

tab()

const popupBtn = document.querySelectorAll('.get__link');
const modal = document.querySelector('.modal__overflow');
const crossClose = document.querySelector('.modal__cross');
const confirm = document.querySelector('.modal__confirm');
const confMark = document.querySelector('.arrowww');

popupBtn.forEach(function (e) {
    e.addEventListener('click', function () {
        modal.classList.add('show');
        if (window.innerWidth <= 768) {
            document.body.style.overflow = "auto";
        }
    });
});

const confBtn = document.querySelector('.modal__confirm button');

confBtn.addEventListener('click', function () {
    modal.classList.remove('show');
    confirm.classList.remove('show');
    if (window.innerWidth <= 768) {
        document.body.style.overflow = "auto";
    }
});
const ConfScreen = document.querySelector('.modal__confirm');

const form = document.querySelector('.form');

function validation(form) {
    let result = true;

    function removeError(input) {
        const parent = input.parentNode;

        if (parent.classList.contains('error')) {
            parent.classList.remove('error');
        }
    }

    function clearErrors(form) {
        const errorMessages = form.querySelectorAll('.error__message');
        errorMessages.forEach(message => message.remove());

        const errorInputs = form.querySelectorAll('.error');
        errorInputs.forEach(input => input.classList.remove('error'));
    }

    function createError(input, text) {
        const parent = input.parentNode;
        const errorLabel = document.createElement('label');

        errorLabel.classList.add('error__message');
        errorLabel.textContent = text
        parent.classList.add('error');
        parent.append(errorLabel)
    }

    function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    clearErrors(form);

    const allInputs = form.querySelectorAll('input');

    for (const input of allInputs) {

        removeError(input);

        if (input.dataset.min) {
            if (input.value.length < input.dataset.min) {
                removeError(input);
                createError(input, `Минимальное кол-во символов ${input.dataset.min}`);
                result = false;
            }
        }

        if (input.dataset.required) {
            if (input.dataset.required == "true" && input.value == "") {
                removeError(input);
                createError(input, 'Поле не заполнено');
                result = false;
            }
        }

        if (input.type === 'email') {
            if (!validateEmail(input.value)) {
                removeError(input);
                createError(input, 'Некорректный формат email');
                result = false;
            }
        }
    }
    return result
}

document.querySelector('form').addEventListener('submit', function (event) {
    event.preventDefault();

    if (validation(this) == true) {

        const xhr = new XMLHttpRequest();
        const method = this.getAttribute('method');
        const action = this.getAttribute('action');
        const data = new FormData(this);
        xhr.open(method, action);
        xhr.onload = function () {
            ConfScreen.classList.add('show');
            confMark.classList.add('active');
            clearErrors(modal);
            clearInputs(modal);
        };

        xhr.send(data);
    }
})

function clearErrors() {
    const errorMessages = document.querySelectorAll('.error__message');
    errorMessages.forEach(message => message.remove());

    const errorInputs = document.querySelectorAll('.error');
    errorInputs.forEach(input => input.classList.remove('error'));
}

clearErrors(form);

const input = form.querySelectorAll('.form__input input');

input.forEach(input => {
    input.addEventListener('input', () => {
        input.parentElement.classList.remove('error');
    });
})

function clearInputs() {
    const inputs = document.querySelectorAll('.form__input input');
    inputs.forEach(input => input.value = "");
}

crossClose.addEventListener('click', function () {
    modal.classList.remove('show');
    confirm.classList.remove('show');
    if (window.innerWidth <= 768) {
        document.body.style.overflow = "auto";
    }
    clearErrors(modal);
    clearInputs(modal);
});
